import Vue from 'vue'
import App from './App.vue'


import VueNotification from "vue-notification";
import VueSweetalert2 from "vue-sweetalert2";
import VueCookies from "vue-cookies";
import router from './router';
import store from './store';
import http from "./http";
import './utils/fontawesome';
import './utils/filters';
import TokenService from "./services/token.service";
import 'sweetalert2/dist/sweetalert2.min.css';

// import AuthorizationException from "@/components/ui/AuthorizationException";

import 'bootstrap/dist/css/bootstrap.min.css';
import {Tooltip} from "bootstrap";

new Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']"
});


Vue.config.productionTip = false
Vue.config.devtools = true;

Vue.use(VueNotification);
Vue.use(VueSweetalert2);
Vue.use(VueCookies);

Vue.directive('permcheck', {

    inserted: function (el, binding, vnode) {

        if (!JSON.parse(localStorage.getItem('permissions'))
            .includes(binding.expression.replace(/'/g, "").replace(/"/g, ""))
        ) {
            const wrapper = document.createElement('div')
            wrapper.innerHTML = "<div class='shadow p-3 mb-5 bg-body rounded' style='border: 1px solid red'>" +
                "<p class='p-3 text-center' style='color: #b7323e' >" +
                "You are not authorized to view this resource</p>" +
                "</div>"
            // vnode.elm.parentElement.removeChild(vnode.elm);
            vnode.elm.parentElement.replaceChild(wrapper, vnode.elm);
        }
    },
})

http.init();

if (TokenService.getAccessToken()) {
    http.setHeader();
    http.mountAuthInterceptor();
}


new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')
