import UserService from "../../services/user.service";

const state = {
    userList: [],
};

const mutations = {
    set_user_list(state, payload) {
        state.userList = payload
    },

};

const actions = {
    async getUsers({commit}) {
        const users = await UserService.getUsers();
        commit("set_user_list", users);
        return users;
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
