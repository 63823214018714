import http from "../http";


const GenericService = {
    getDepartmentsService: async () => {
        const departments = await http.instance.get("/generic/getdepartments");
        return departments.data
    },
};

export default GenericService