<template>
    <div class="notification is-danger">
        <p class="heading has-text-centered has-text-weight-bold">This resource is forbidden to access.</p>
    </div>
</template>

<script>
    export default {
        name: "ForbiddenPage",
        data(){
            return{

            }
        }
    }
</script>

<style scoped>

</style>
