import Vue from 'vue';
import Vuex from 'vuex';

import UsersModule from "./modules/users.module"
import AuthModule from "./modules/auth.module"
import GenericModule from "./modules/generic.module"



Vue.use(Vuex);
const store = new Vuex.Store();

store.registerModule("auth", AuthModule);
store.registerModule("users", UsersModule);
store.registerModule("generic", GenericModule);


export default store
