import Vue from 'vue';

Vue.filter("shortFormatDateString", (value) => {
    const date = new Date(value);
    return date.toLocaleString(['en-US'], {day: '2-digit', month: 'short', year: 'numeric'})
});

Vue.filter("longFormatDateFromString", (value) => {
    const date = new Date(value);
    return date.toLocaleString(['en-US'], {day: '2-digit', month: 'short', year: 'numeric'})
});

Vue.filter("padleftZeroFormat", (value) => {
    return value.toString().padStart(3, '0')
});


Vue.filter("longFormatFromDT", (value) => {
    const date = new Date(value);

    return date.toLocaleString(['en-CA'], {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    })
});


Vue.filter("longHourAndMinuteFromDT", (value) => {
    const date = new Date(value);

    return date.toLocaleString(['tr-TR'], {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
    })
});

Vue.filter("formatFloatValue", (value) => {
    return value.toString().substring(0, 10);
});

Vue.filter("nameReplace", (value) => {
    return value.replace('_', ' ');
});
Vue.filter("airpasplanstat", (value) => {
    if (value == 0) {
        return "Created";
    }
    if (value == 1) {
        return "Success";
    }
    if (value == 2) {
        return "Error";
    }
    if (value == 3) {
        return "Cancelled";
    } else {
        return "none";
    }
});

Vue.filter("airpasplanruntype", (value) => {
    if (value == 'F') {
        return "Forced";
    }
    if (value == 'A') {
        return "Auto";
    }
    if (value == 'D') {
        return "User";
    } else {
        return "none";
    }
});

Vue.filter("airpasplanerror", (value) => {
    if (value != '') {
        return value.substring(0,3) + '...';
    }
});


Vue.filter("nameReplaceUpperSpace", (value) => {
    var rex = /([A-Z])/g;
    return value.replace(rex, ' $1')
});

Vue.filter("shortFormatDateNumeric", (value) => {
    const date = new Date(value);
    //  return date.toLocaleString(['en-US'], {day: 'numeric', month: 'numeric', year: 'numeric'})
    return date.toLocaleString(['tr-TR'], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    })
});

Vue.filter("formatDateNumericWithHour", (value) => {
    const date = new Date(value);
    //  return date.format('YYYY-MM-DD HH:mm:ss');
    return date.toLocaleString(['tr-TR'], {
        // year: 'numeric',
        // month: 'numeric',
        // day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23'
    })
});

Vue.filter("formatTimeAddTwoPointsBetwHHmm", (value) => {
    return value.substring(0, 2) + ":" + value.substring(2, 4);
});

Vue.filter("formatDateOnlyMonthDate", (value) => {
    const date = new Date(value);
    return date.toLocaleString(['en-EN'], {
        month: 'short',
        day: 'numeric'
    })
});
Vue.filter("formatDegree", (value) => {
    return Math.round(value);
})

Vue.filter("formatStringLenght", (value) => {
    const dizi = value.split(" ");
    var x = dizi.length;
    const son = [];
    var i;
    var mapObj = {
        ',': ' '
    };
    // eslint-disable-next-line for-direction
    for (i = 0; i < x; i++) {
        if (dizi[i].length > 11) {
            var a = dizi[i].substring(0, 8) + '..';
            son.push(a);
        } else {
            son.push(dizi[i]);
        }
    }
    const b = son.toString();
    var d = b.replace(/,/gi, function (matched) {
        return mapObj[matched];
    });
    return d
});

Vue.filter("listOrderByStd", (value) => {

    return value.sortBy(self.value, 'std', -1);
})
