<template>
    <div id="app">
        <div>
            <AppHeader/>
            <LeftMenu/>
<!--            <AppFooter/>-->
        </div>

        <notifications :duration="1500" style="font-size: medium"/>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    import AppHeader from "./components/layout/AppHeader";
/*    import AppFooter from "./components/layout/AppFooter";*/

    import LeftMenu from "./components/layout/LeftMenu";


    export default {
        name: 'App',
        components: {
          /*            AppFooter,*/
          AppHeader,

            LeftMenu
        },
        computed: {
            ...mapGetters("auth", ["isAuthenticated"]),
            rename() {
                return 'name'
            },

            isAuthenticatedAuth() {
                console.log("isAuthenticatedAuth")
                let routename = localStorage.getItem('route_name')
                console.log("routename")
                console.log(routename)
                if (!this.isAuthenticated && routename != "SingleSignOnRouter") {
                    return false;
                } else {
                    return true;
                }

            },

        },
    }
</script>

<style>
    .app-main {
        padding: 1.5rem;
    }
    .dashboard-border{
        margin-top: 20px;
        padding:10px;
        box-shadow: 0px 3px 10px #ced4da;
    }
    .dashboard-border span{
        font-family: Helvetica, Arial;
        font-size: 18px;
        color: #383838;
        font-weight: 600;
    }

    .table {
        width: 100%;
    }

    .table td {
        border: 0px;
    }

    .modified-table {
        /*overflow-y: scroll;*/
        max-height: 650px;
    }

    .modified-table thead th {
        color: white;
        background-color: #56759c;
        font-style: normal;
        font-size: 15px;
        top: -1px;
        position: sticky;
        z-index: 2;
        font-family: Helvetica, Arial;
    }
    .modified-table td {
        border: 0px;
        font-size: 16px;
        font-family: Helvetica, Arial;
        text-align: center;
    }

    .modified-table th {
        top: -12px;
        positiairports-tableon: sticky;
        background-color: white;
        z-index: 2;
        text-align: center;
    }
    @media (max-width: 767px) {
        .dashboard-border span{
            font-size: 16px;
        }
        .modified-table thead th {
            font-size: 14px;
        }
        .modified-table td {
            font-size: 14px;
        }

    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #292961
    }


</style>
