import Vue from 'vue';

import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
    faUser,
    faEnvelope,
    faLock,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowCircleLeft,
    faArrowCircleRight,
    faEdit,
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faCloud,
    faFolder,
    faFan,
    faFilePdf,
    faCheckCircle,
    faFile,
    faFileAlt,
    faCompressArrowsAlt,
    faArrowsAlt,
    faWindowClose,
    faPencilAlt,
    faPen,
    faSave,
    faRoute,
    faImage,
    faTimes,
    faTabletAlt,
    faMap,
    faCaretUp,
    faFileSignature,
    faClock,
    faPlaneDeparture,
    faCaretSquareUp,
    faHandPointUp,
    faSignInAlt,
    faPowerOff,
    faArrowDown,
    faArrowUp,
    faStickyNote,
    faExclamationTriangle,
    faSignOutAlt,
    faPlusCircle,
    faSync,
    faSyncAlt,
    faTrashAlt,
    faSpinner,
    faCog,
    faPlus,
    faCheckSquare,
    faCheck,
    faAngleDown,
    faAngleUp,
    faAngleRight,
    faAngleLeft,
    faLongArrowAltLeft,
    faCogs,
    faArrowRight,
    faArrowLeft,
    faEye,
    faLongArrowAltRight,
    faSearch,
    faSearchPlus,
    faPlayCircle,
    faPlay,
    faSortDown,
    faSortUp,
    faList,
    faFileExcel,
    faFilter,
    faInfoCircle,
    faInfo,
    faPlane,
    faClone,
    faMarker,
    faMinus,



} from '@fortawesome/free-solid-svg-icons';

library.add(
    faUser,
    faEnvelope,
    faLock,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowCircleLeft,
    faArrowCircleRight,
    faEdit,
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faCloud,
    faFolder,
    faFan,
    faFilePdf,
    faCheckCircle,
    faFile,
    faFileAlt,
    faCompressArrowsAlt,
    faArrowsAlt,
    faWindowClose,
    faPencilAlt,
    faPen,
    faSave,
    faRoute,
    faImage,
    faTimes,
    faTabletAlt,
    faMap,
    faCaretUp,
    faFileSignature,
    faClock,
    faPlaneDeparture,
    faCaretSquareUp,
    faHandPointUp,
    faSignInAlt,
    faPowerOff,
    faArrowDown,
    faArrowUp,
    faStickyNote,
    faExclamationTriangle,
    faSignOutAlt,
    faPlusCircle,
    faSync,
    faSyncAlt,
    faTrashAlt,
    faSpinner,
    faCog,
    faPlus,
    faCheckSquare,
    faCheck,
    faAngleDown,
    faAngleUp,
    faAngleRight,
    faAngleLeft,
    faLongArrowAltLeft,
    faCogs,
    faArrowRight,
    faArrowLeft,
    faEye,
    faLongArrowAltRight,
    faSearch,
    faSearchPlus,
    faPlayCircle,
    faPlay,
    faSortDown,
    faSortUp,
    faList,
    faFileExcel,
    faFilter,
    faInfoCircle,
    faInfo,
    faPlane,
    faClone,
    faMarker,
    faMinus

);

Vue.component('font-awesome-icon', FontAwesomeIcon);
