import Vue from 'vue'
import VueRouter from 'vue-router'


import Forbidden from "../pages/ForbiddenPage";
import TestGrid_Page from "@/pages/TestGrid_Page";

Vue.use(VueRouter);

// /**
//  * Route meta:
//  * - public
//  * - requiresLogout
//  */

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes: [


             /* Netline Mails */
        {
            path: '/',
            name: 'TestGrid_Page',
            component: TestGrid_Page
        },

        {
            name: "Forbidden",
            path: "/403",
            component: Forbidden
        }
    ]
});

router.beforeEach((to, from, next) => {

    let single = to.fullPath.substring(1, 7);
    const isPublic = to.matched.some(r => r.meta.public);
    const requiresLogout = to.matched.some(r => r.meta.requiresLogout);
    //const requiredPermission = to.matched[0].meta.requiredPermission;
    const isAuthenticated = true;

    if (isPublic || single === 'Single') {
        let params = to.fullPath.substring(20);
        console.log(params)
        let routename = to.name;
        let newArr = [];
        newArr = params.split(',');
        localStorage.setItem("userEmail", newArr[0]);
        localStorage.setItem("auth_token", newArr[1]);
        localStorage.setItem("route_name", routename)
    }

    if (!isAuthenticated && isPublic) {
        return next({
            path: 'Login',
            query: {redirect: to.fullPath}
        });
    }

    if (isAuthenticated && requiresLogout) {
        return next('/');
    }

    // if (requiredPermission && !AuthService.hasPermission(requiredPermission)) {
    //     return next('/403');
    // }

    next();
});

//loading start//
router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        // eslint-disable-next-line no-undef
        NProgress.start()
    }
    next()
});
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    // eslint-disable-next-line no-undef
    NProgress.done()
});
//loading end//

export default router
