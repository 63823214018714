import GenericService from "../../services/generic.service";

const state = {
    departments: [],
};

const mutations = {
    set_department_list(state, payload) {
        state.departments = payload
    },
};

const actions = {
    async getDepartments({commit}) {
        const departments = await GenericService.getDepartmentsService();
        commit("set_department_list", departments);
        return departments;
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
