import AuthService from "../../services/auth.service";
import router from "../../router";

const state = {
    user: localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")),

};
console.log("user")
console.log(state.user)
const getters = {
    isAuthenticated: (state) => !!state.user
};

const mutations = {
    auth_success(state, user) {
        state.user = user
    },
    auth_clear(state) {
        state.user = null
    },
};

const actions = {

    async login({commit}, {email, password,token}) {
        var user = await AuthService.login(email, password,token);
        commit("auth_success", user);
        //console.log(returnUrl);
        console.log("user.email")
        console.log(user.email)
        //window.location.reload()
        router.push(router.history.current.query.redirect || "/");
    },
    async refreshToken({commit}) {
        var user = await AuthService.refreshToken();
        commit("auth_success", user);
    },
    logout({commit}) {
        router.push("/")
        AuthService.logout();
        commit("auth_clear");
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
