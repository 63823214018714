<template>
  <div class="container-fluid TestGrid_Page px-0">
    <div class="row g-2 px-0">
        <div class="col-6">
          <div class="row g-3 align-items-center">
            <div class="col-3">
              <label class="col-form-label">Yenileme Hızı({{RefreshSpeed}} ms)</label>
            </div>
            <div class="col-7">
              <input type="range"  v-model="RefreshSpeed" class="form-range" min="100" max="10000" step="100" id="customRange3">
            </div>
            <div class="col-2">
              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-primary" @click="(RefreshSpeed<=100)? RefreshSpeed=100: RefreshSpeed-=100">
                  <font-awesome-icon icon="minus"  />
                </label>
                <label class="btn btn-primary"  @click="(RefreshSpeed>=10000)? RefreshSpeed=10000: RefreshSpeed+=100" >
                  <font-awesome-icon icon="plus" />
                </label>
              </div>
            </div>
          </div>

        </div>


      <!--liste-->
      <div class="col-md-12 G_table-container px-0" v-if="TestGrid_Data.length>0">
        <table class="normal" id="ReportTable" >
          <thead id="MailList_Header" >
          <tr>
            <th v-for="(field,index) in this.TestGrid_FieldList.filter(x=>x.IsShow==true)" :key="index" >
              <div class="d-flex flex-column align-items-start">
<!--                <div  @click="sortGridBy(field.fieldName)">-->
                <div>
                {{ field.fieldDisplayName }}
                <font-awesome-icon  v-if="SortField===field.fieldName && SortDirection==='desc'"  icon="sort-down" />
                <font-awesome-icon  v-if="SortField===field.fieldName && SortDirection==='asc'"  icon="sort-up" />
                </div>
              </div>
            </th>
            <th>
              Chart
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row,index) in this.FilteredtestGridData" :key="index" :id="'currencyRow_'+row.ID" >
            <td>{{row.ID}}</td>
            <td><img :src="'../../img/'+row.Code+'.png'"  width="69" height="45" /></td>
            <td>{{row.Code+' - '+row.Currency}}</td>
            <td>{{row.Units}}</td>
            <td class="ticker"  :class="row.BidPriceChangeType" :id="'currencyRow_Bid_'+row.ID" >{{row.Bid}}</td>
            <td class="ticker"  :class="row.AskPriceChangeType" :id="'currencyRow_Ask_'+row.ID" >{{row.Ask}}</td>
            <td><LineChart style="width: 670px; height: 150px;" :chartData="GetCurrencyChartData(row)" /> </td>
          </tr>
          </tbody>
        </table>

      </div>

    </div>


  </div>



</template>


<script>
import {GlobalFunctionAndStateMixin} from "../utils/mixins";
import LineChart from '../components/Chart/Line'

const  enumPriceChangeType = Object.freeze({
  None_00     : 'flare-circle'  ,
  Up_01     : 'flare-up' ,
  Down_02  : 'flare-down' ,
});


export default {
  mixins:[GlobalFunctionAndStateMixin],
  components: {
    LineChart
  },
  data() {
    return {
      enumPriceChangeType:enumPriceChangeType,
      RefreshSpeed:1000,
      ErrMsg:"",
      TestGrid_Data:[
        {
           ID:1
          ,Country:"US"
          ,Code: "USD"
          ,Currency:"Amerikan Doları"
          ,Units:"USD/TRY"
          ,Bid:28.2462
          ,Ask:28.2511
          ,PriceHistory:[28.2062,28.2462,28.2162,28.1962,28.2262,28.2062,28.2462,28.2162,28.1962,28.2262,28.1962,28.2262,28.2062,28.2462,28.2162,28.2062,28.2462,28.2162,28.1962,28.2262,28.2062,28.2462,28.2162,28.1962,28.2262,28.1962,28.2262,28.2062,28.2462,28.2162]
          ,BidPriceChangeType:enumPriceChangeType.None_00
          ,AskPriceChangeType:enumPriceChangeType.None_00
        },
        {
           ID:2
          ,Country:"EU"
          ,Code: "EUR"
          ,Currency:"EURO"
          ,Units:"EUR/TRY"
          ,Bid:29.8385
          ,Ask:29.8545
          ,PriceHistory:[29.8385,29.8085,29.8485,29.8185,29.8385,29.8385,29.8085,29.8485,29.8185,29.8385,29.8485,29.8185,29.8385,29.8385,29.8085,29.8385,29.8085,29.8485,29.8185,29.8385,29.8385,29.8085,29.8485,29.8185,29.8385,29.8485,29.8185,29.8385,29.8385,29.8085]
          ,BidPriceChangeType:enumPriceChangeType.None_00
          ,AskPriceChangeType:enumPriceChangeType.None_00
        },
        {
          ID:3
          ,Country:"JP"
          ,Code: "JPY"
          ,Currency:"100 Japon Yeni"
          ,Units:"JPY/TRY"
          ,Bid:18.8120
          ,Ask:19.1760
          ,PriceHistory:[18.8120,18.7820,18.8220,18.8420,18.8020,18.8120,18.7820,18.8220,18.8420,18.8020,18.8420,18.8020,18.8120,18.7820,18.8220,18.8120,18.7820,18.8220,18.8420,18.8020,18.8120,18.7820,18.8220,18.8420,18.8020,18.8420,18.8020,18.8120,18.7820,18.8220]
          ,BidPriceChangeType:enumPriceChangeType.None_00
          ,AskPriceChangeType:enumPriceChangeType.None_00
        },
        {
          ID:4
          ,Country:"GB"
          ,Code: "GBP"
          ,Currency:"İngiliz Sterlini"
          ,Units:"GBP/TRY"
          ,Bid:34.1865
          ,Ask:34.1895
          ,PriceHistory:[34.2280,34.2580,34.2380,34.2080,34.2680,34.2280,34.2580,34.2380,34.2080,34.2680,34.2080,34.2680,34.2280,34.2580,34.2380,34.2280,34.2580,34.2380,34.2080,34.2680,34.2280,34.2580,34.2380,34.2080,34.2680,34.2080,34.2680,34.2280,34.2580,34.2380]
          ,BidPriceChangeType:enumPriceChangeType.None_00
          ,AskPriceChangeType:enumPriceChangeType.None_00
        },
        {
          ID:5
          ,Country:"CH"
          ,Code: "CHF"
          ,Currency:"İsviçre Frangı"
          ,Units:"CHF/TRY"
          ,Bid:31.2580
          ,Ask:31.2791
          ,PriceHistory:[31.2280,31.2080,31.2480,31.2680,31.2580,31.2280,31.2080,31.2480,31.2680,31.2580,31.2680,31.2580,31.2280,31.2080,31.2480,31.2280,31.2080,31.2480,31.2680,31.2580,31.2280,31.2080,31.2480,31.2680,31.2580,31.2680,31.2580,31.2280,31.2080,31.2480]
          ,BidPriceChangeType:enumPriceChangeType.None_00
          ,AskPriceChangeType:enumPriceChangeType.None_00
        }
      ],
      TestGrid_FieldList:[
         {fieldName:"ID"              , fieldDisplayName:"ID",          IsShow:true,   }
        ,{fieldName:"Country"         , fieldDisplayName:"Country",     IsShow:true    }
        ,{fieldName:"Code"            , fieldDisplayName:"Currency",    IsShow:true    }
        ,{fieldName:"Currency"        , fieldDisplayName:"Currency",    IsShow:false   }
        ,{fieldName:"Units"           , fieldDisplayName:"Units",       IsShow:true    }
        ,{fieldName:"Bid"             , fieldDisplayName:"Bid",         IsShow:true    }
        ,{fieldName:"Ask"             , fieldDisplayName:"Ask",         IsShow:true    }
      ],
      SortField:"",
      SortDirection:"asc",
      ActiveBagMessage:"",
      IsLoadingData:true,
      IsExportingExcel:false,
      currentDate:new Date(),

      chartData: {
        labels: ['', '', '', '', '','', '', '', '', '','', '', '', '', '', ''],
        options: {
          scales: {
            yAxes: [{
              gridLines: {
                display: false,
              },
            }]
          },

          interaction: {
            intersect: false
          },
          plugins: {
            legend: false
          },
          animation: false,
          hover: {
            animationDuration: 0,
          },
          responsiveAnimationDuration: 0,
          normalized: true,
          legend: {
            display: false
          },
          showLine: false,
          spanGaps: true,
          parsing: false
        },
        datasets: [
          {
            label: 'USD/TRY',
            backgroundColor: "#535353",
            borderColor: "#535353",
            pointRadius: 2,
            data: []
          }
        ]
      },

      EmptyArray:[],

    }
  },
  async created() {
    let NowDate = this.splitDate(new Date()).formatted_dateYYYYMMDD;

    if(NowDate==this.splitDate(this.fStartDate).formatted_dateYYYYMMDD){
      this.fStartDate = this.splitDate(new Date()).formatted_date;
      this.fFinishDate = this.splitDate(new Date()).formatted_date;
    }

    await this.refresh_Data();

  },
  mounted: function () {
    setInterval(function () {
      this.Timer_loop();
    }.bind(this), 50)
  },
  methods: {

    /////////////////////////Refresh Start/////////////////////////////////

    Timer_loop(){

      let newDate = new Date();
      if(newDate - this.currentDate >=parseInt(this.RefreshSpeed)) {
        this.Refresh_TestGridData();
        this.currentDate = newDate;
      }
      newDate = null;

    },


    //Tüm datayı refresh ediyor
    async refresh_Data(){
      await this.Refresh_TestGridData();
    },



    Refresh_TestGridData(){
      for(let i=0; i<this.TestGrid_Data.length; i++) {
        let Currency = this.TestGrid_Data[i];
        this.Randomize_Currency(Currency);
        Currency = null;
      }
    },


    /////////////////////////Refresh Finish/////////////////////////////////
    ramdomNumber(count) {
      return parseInt(Math.random()*count);
    },

    GetCurrencyChartData(Currency){
      let data = JSON.parse(JSON.stringify(this.chartData));
      data.datasets[0].label = Currency.Units;
      data.datasets[0].data = Currency.PriceHistory;

      let arrayList = [];
      for(let i = 0; i < Currency.PriceHistory.length; i++) {
        arrayList.push('');
      }
      data.labels =arrayList;
      arrayList=null;
      return data;
    },

    async Randomize_Currency(Currency){
      try {
        let ramdomNumber= this.ramdomNumber(60);

        let BidPriceMultiplier = (ramdomNumber<20)?0:(ramdomNumber<40)? 1: -1;
        let AskPriceMultiplier = (ramdomNumber<20)?0:(ramdomNumber<40)? -1: 1;

        let NewBidPrice =Number(Currency.Bid);
        let NewAskPrice =Number(Currency.Ask);
        if(BidPriceMultiplier!==0) NewBidPrice +=Number(0.01*BidPriceMultiplier);
        if(AskPriceMultiplier!==0) NewAskPrice +=Number(0.01*AskPriceMultiplier);


        Currency.BidPriceChangeType =NewBidPrice>Currency.Bid ? enumPriceChangeType.Up_01 :NewBidPrice<Currency.Bid ? enumPriceChangeType.Down_02 : enumPriceChangeType.None_00;
        Currency.AskPriceChangeType =NewAskPrice>Currency.Ask ? enumPriceChangeType.Up_01 :NewBidPrice<Currency.Bid ? enumPriceChangeType.Down_02 : enumPriceChangeType.None_00;

        Currency.Bid =  parseFloat(NewBidPrice.toString()).toFixed(4);
        Currency.Ask = parseFloat(NewAskPrice.toString()).toFixed(4);

        if(Currency.PriceHistory.length>30) {
          Currency.PriceHistory.shift();
        }
        Currency.PriceHistory.push(Currency.Bid);



        ramdomNumber = null;
        BidPriceMultiplier = null;
        AskPriceMultiplier = null;
        NewBidPrice = null;
        NewAskPrice = null;

      }catch (e) {
        console.log(e);
      }


    },


  },

  computed:{
    FilteredtestGridData: function () {
      return this.TestGrid_Data;
    }


    },

}
</script>
<style>

.btn_hovered:hover{
  opacity: 0.5;
}

.TestGrid_Page .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.TestGrid_Page .ClearSelectedLegnoBtn{
  line-height: 1;
  cursor:pointer;
  margin-right: 5px;
  color: #ffffff;
  background-color: rgb(86, 117, 156);
}

.TestGrid_Page .ClearSelectedLegnoBtn:hover {
  opacity: 0.8;
  color: #ffffff;
  background-color: rgb(86, 117, 156);
}

.TestGrid_Page .Flex_Horizontal{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  color: rgb(86, 117, 156);
}

.TestGrid_Page [layout] {
  display: flex;
}

.TestGrid_Page [layout=row],
.TestGrid_Page [layout="row nowrap"] {
  flex-flow: row nowrap;
}

.TestGrid_Page [layout="row wrap"] {
  flex-flow: row wrap;
}

.TestGrid_Page [layout=row-reverse] [layout="row-reverse nowrap"] {
  flex-flow: row-reverse nowrap;
}

.TestGrid_Page [layout="row-reverse wrap"] {
  flex-flow: row-reverse wrap;
}

.TestGrid_Page [layout=column],
.TestGrid_Page [layout="column nowrap"] {
  flex-flow: column nowrap;
}

.TestGrid_Page [layout="column wrap"] {
  flex-flow: column wrap;
}

.TestGrid_Page [layout=column-reverse],
.TestGrid_Page [layout="column-reverse nowrap"] {
  flex-direction: column-reverse;
}

.TestGrid_Page [layout="column-reverse wrap"] {
  flex-flow: column-reverse wrap;
}

.TestGrid_Page [flex] {
  flex: 1 1 0;
}

.TestGrid_Page [flex=auto] {
  flex: 1 1 auto;
}

.TestGrid_Page [flex=grow] {
  flex: 1 1 100%;
}

.TestGrid_Page [flex=nogrow] {
  flex: 0 1 auto;
}

.TestGrid_Page [flex=noshrink] {
  flex: 1 0 auto;
}

.TestGrid_Page [flex=none] {
  flex: 0 0 auto;
}

.TestGrid_Page [layout-align=space-around] {
  justify-content: space-around;
}

.TestGrid_Page [order="1"] {
  order: 1;
}

.TestGrid_Page [order="2"] {
  order: 2;
}

.TestGrid_Page [order="3"] {
  order: 3;
}

.TestGrid_Page [order="4"] {
  order: 4;
}

.TestGrid_Page [order="5"] {
  order: 5;
}

@media screen and (max-width: 767px) {
  .TestGrid_Page  [xs-layout] {
    display: flex;
  }

  .TestGrid_Page [xs-layout=row],
  .TestGrid_Page  [xs-layout="row nowrap"] {
    flex-flow: row nowrap;
  }

  .TestGrid_Page [xs-layout="row wrap"] {
    flex-flow: row wrap;
  }

  .TestGrid_Page [xs-layout=row-reverse] [xs-layout="row-reverse nowrap"] {
    flex-flow: row-reverse nowrap;
  }

  .TestGrid_Page [xs-layout="row-reverse wrap"] {
    flex-flow: row-reverse wrap;
  }

  .TestGrid_Page [xs-layout=column],
  .TestGrid_Page [xs-layout="column nowrap"] {
    flex-flow: column nowrap;
  }

  .TestGrid_Page [xs-layout="column wrap"] {
    flex-flow: column wrap;
  }

  .TestGrid_Page [xs-layout=column-reverse],
  .TestGrid_Page [xs-layout="column-reverse nowrap"] {
    flex-direction: column-reverse;
  }

  .TestGrid_Page [xs-layout="column-reverse wrap"] {
    flex-flow: column-reverse wrap;
  }

  .TestGrid_Page [xs-flex] {
    flex: 1 1 0;
  }

  .TestGrid_Page [xs-flex=auto] {
    flex: 1 1 auto;
  }

  .TestGrid_Page [xs-flex=grow] {
    flex: 1 1 100%;
  }

  .TestGrid_Page [xs-flex=nogrow] {
    flex: 0 1 auto;
  }

  .TestGrid_Page [xs-flex=noshrink] {
    flex: 1 0 auto;
  }

  .TestGrid_Page [xs-flex=none] {
    flex: 0 0 auto;
  }

  .TestGrid_Page [xs-order="1"] {
    order: 1;
  }

  .TestGrid_Page [xs-order="2"] {
    order: 2;
  }

  .TestGrid_Page [xs-order="3"] {
    order: 3;
  }

  .TestGrid_Page [xs-order="4"] {
    order: 4;
  }

  .TestGrid_Page [xs-order="5"] {
    order: 5;
  }
}
.TestGrid_Page .c-card {
  background-color: #FFF;
  align-items: stretch;
/*  margin: 2px;*/
/*  padding: 20px 20px 20px 4px;*/
  font-size: 15px;
  border-bottom: 1px #cacaca solid;
}
.TestGrid_Page .c-card.active, .TestGrid_Page .c-card.active .c-card__body{
  background-color: #f2f5f8;
}


.TestGrid_Page  .c-card.notsame, .TestGrid_Page  .c-card.notsame .c-card__body{
  background-color:#ffdede;
}


.TestGrid_Page .c-card .c-card__body {
  padding: 0 0 0 10px;
}
@media screen and (min-width: 768px) {
  .TestGrid_Page  .c-card .c-card__body {
    align-items: stretch;
    padding: 0;
    background-color: #FFF;
  }
}
@media screen and (min-width: 768px) {
  .TestGrid_Page .c-card {
    padding: 0;
  }
}

.TestGrid_Page .c-flight-card {

}
.TestGrid_Page .c-flight-card sub {
  font-size: 70%;
  font-weight: normal;
  color: #999;
  display: block;
  line-height: 1.2;
  position: static;
}
.TestGrid_Page .c-flight-card .c-flight-card__flight-schedule {
  width: calc(100% - 32px - 32px);
  text-align: left;
}
@media screen and (min-width: 768px) {
  .TestGrid_Page .c-flight-card .c-flight-card__flight-schedule {
    padding: 4px 16px;
    width: auto;
  }
}
.TestGrid_Page .c-flight-card .c-flight-card__duration {
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .TestGrid_Page .c-flight-card .c-flight-card__duration strong {
    font-size: 70%;
    font-weight: normal;
    color: #999;
    display: block;
    line-height: 1.2;
    position: static;
  }
}
@media screen and (min-width: 768px) {
  .TestGrid_Page .c-flight-card .c-flight-card__duration {
    padding: 4px 10px;
    text-align: center;
    width: 140px;
  }
}
.TestGrid_Page .c-flight-card .c-flight-card__action {
  padding: 0 0 0 8px;
  transition: background-color 0.5s;
}
.TestGrid_Page .card--external.c-flight-card .c-flight-card__action {
  align-items: center;
}

@media screen and (min-width: 768px) {
  .TestGrid_Page .c-flight-card .c-flight-card__action {
    align-items: center;
    background-color: #F2F5F8;
    padding: 4px 20px;
  }
}
.TestGrid_Page .c-flight-card .c-flight-card__action:hover {
  background-color: #e2e7ed;
  cursor: pointer;
}


.input_filter_flights .filter_input{
  border-radius: 0;
  border: 2px rgb(86, 117, 156) solid;
  margin-bottom: 0;
  float: right;
}

.input_filter_flights .filter_input:focus,.input_filter_flights .filter_input:active{
  outline: none !important;
  /* border: none !important; */
  box-shadow: none !important;
  border-color: #d4d7dd !important;
}

.TestGrid_Page .G_table-container{
  margin:auto;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  max-height:  calc(100vh - 65px);
  min-height:  calc(100vh - 65px);
}

.TestGrid_Page .table-title {
  background: rgb(86, 117, 156);
  color: #fff;
  padding: 6px 6px;
  margin: 0;
  border-radius: 0;
  width: 100%;
}

.TestGrid_Page .table-title h2 {
  margin: 5px 0 0;
  font-size: 20px;
  text-align: left;
  line-height: 1.5;
}

.TestGrid_Page .table-title .btn,.TestGrid_Page   .G_table-container .table-title .input{
  color: #fff;
  float: right;
  font-size: 13px;
  border: none;
  min-width: 50px;
  border-radius: 2px;
  border: none;
  outline: none !important;
  margin-left: 10px;
}

.TestGrid_Page .G_table-container table{
  border-collapse:collapse;
  width:100%;
}

.TestGrid_Page .G_table-container table.normal{
  border: 1px solid #f1f2f5;
}

.TestGrid_Page .G_table-container thead{
  background:rgb(86, 117, 156);
  color:#fff;
  height: 42px;
  position: sticky;
  top: -1px; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.TestGrid_Page .G_table-container tfoot{
  position: sticky;
  bottom: -2px;
  background: rgb(86, 117, 156);
  color: #fff;
  height: 30px;
}

.TestGrid_Page .G_table-container th,.TestGrid_Page .G_table-container td{
  text-align:left;
  padding:.25em .5em;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 315px;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
 /* border-right: 1px #e1e1e1 solid;*/
}

.TestGrid_Page .G_table-container tbody tr:nth-child(even){
  background: #F2F5F8;
}

.TestGrid_Page .G_table-container tbody tr:hover{
  background:#e2e7ed;
  /*  color:#FFFFFF;*/
}

.TestGrid_Page .panel_msgtypes{
  /*  margin-bottom: 2px;*/
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: auto;
  align-items: center;
  flex-wrap: nowrap;
}

.TestGrid_Page .panel_msgtypes .badge_msgtype{
  width: 100px;
  height: 50px;
  min-width: 100px;
  min-height: 50px;
  background-color: #f2f5f8;
  border-radius: 2px;
  padding: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 5px;
  text-align: center;
}

.TestGrid_Page .panel_msgtypes .badge_msgtype:hover,.TestGrid_Page  .panel_msgtypes .badge_msgtype.active {
  background-color: rgb(86, 117, 156) !important;
  color: #fff;
}

.TestGrid_Page .panel_msgtypes .badge_msgtype.danger {
  background-color: #b76363;
  color: #fff;
}


.TestGrid_Page .date_filters .btn_refresh{
  border-radius: 3px;
  border: 1px #f2f5f8 solid;
  background-color: #f2f5f8;
  cursor: pointer;
  padding: 6px 18px;
  height: 50px;
}

.TestGrid_Page .date_filters .btn_refresh:hover{
  background-color: #e2e7ed;
}

.TestGrid_Page .date_filters .form-control{
  border-radius: 0;
  padding: 1px;
  border: 1px #f2f5f8 solid;
}

.TestGrid_Page .date_filters .form-control .mx-input{
  border: 0px #f2f5f8 solid;
  box-shadow: none;
  border-radius: 2px;
  cursor: pointer;
  color: #2c3e50;
  height: 46px;
}

.TestGrid_Page .date_filters .form-control .mx-input-wrapper{
  cursor: pointer;
}

.TestGrid_Page .btn_mailInfo{
  background: none;
  border: none;
  color: rgb(86, 117, 156);
  font-size: 16px;
}

.TestGrid_Page .btn_mailInfo:hover{
  color: rgb(86, 117, 156);
}

.TestGrid_Page .modal_input_group{
  text-align: left;
  margin-top: 5px !important;
  padding-right: 15px !important;
}

.TestGrid_Page .modal_input_group .frm_Input{
  border: 1px #ebebeb solid;
  border-radius: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.TestGrid_Page .modal_input_group .form-control{
  border: 1px #ebebeb solid;
  border-radius: 3px;
  background-color: #ecf0f4;
}

.TestGrid_Page .modal_input_group .col-form-label{
  line-height: 1;
  font-size: 14px;
}

.TestGrid_Page .table_headButton{
  cursor: pointer;
  line-height: 1.5;
  color: #fff;
}

.TestGrid_Page .table_headButton:hover{
  opacity: 0.8;
}


.TestGrid_Page .column_Input{
  width: 100%; background-color: #fff;
}

.TestGrid_Page .column_Input input, .TestGrid_Page .column_Input input:focus{
  width: inherit;
  border: 0;
  outline: 0;
}


/* up/down 'flare' animations */
.ticker.flare-up::after {
  width: 0;
  height: 0;
  position: relative;
  float: right;
  margin: 5px 5px 0 2px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid rgba(50, 255, 50, 0.5);
  content: "";
  color: rgba(50, 255, 50, 0.5);
}

.ticker.flare-down::after {
  width: 0;
  height: 0;
  position: relative;
  float: right;
  margin: 5px 5px 0 2px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid rgba(255, 50, 50, 0.5);
  content: "";
  color: rgba(50, 255, 50, 0.5);
}
.ticker.flare-up {
  animation: 300ms ease-out flare-up;
}
@keyframes flare-up {
  from {
    background: rgba(50, 255, 50, 0.5);
  }
}
.ticker.flare-down {
  animation: 300ms ease-out flare-down;
}
@keyframes flare-down {
  from {
    background: rgba(255, 50, 50, 0.5);
  }
}

.ticker{
  width: 180px;
}

/*.ticker.flare-circle {
  animation: 200ms ease-out flare-circle;
}
@keyframes flare-circle {
  from {
    background: rgba(227,246,36, 0.5);
  }
}*/
</style>
