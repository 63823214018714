const TokenService = {
    setToken: (accessToken, refreshToken) => {
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("refresh_token", refreshToken)
    },
    getAccessToken: () => localStorage.getItem("access_token"),
    getRefreshToken: () => localStorage.getItem("refresh_token"),
    removeToken: () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
    }
};

export default TokenService
