var _ = require("lodash");
import moment from "moment";
import Moment from "moment";

let GlobalFunctionAndStateMixin={

    data() {
        return {
            flightBaseUrl:window.location.origin,
            G_SelectedYear:  moment().add(7, 'days').format('GGGG'),
            G_SelectedWeek: moment().add(7, 'days').format('WW'),
            Now_Year:  moment().format('GGGG'),
            Now_Week:moment().format('WW'),
            Now_Date:moment.now(),
            gWeekDay : date => date.getDay(),
            gDay : date => date.getDate(),
            gMonth : date => date.getMonth(),
            gYear : date => date.getFullYear(),

        }
    },


    methods:{

        //Datepickerlarda bu haftadan önceki tarihleri kapatmak için
        disabledDatePickerBeforeByWeek(date) {
            try {
                let weekStartDate = this.splitDate(this.Now_Date).weekStartDate;
                weekStartDate.setHours(0, 0, 0, 0);

                return date < weekStartDate;
            }catch (e){
                console.log(e);
            }
        },



        Get_NextWeekListByWeekCount(StartDay, NextWeekcount){
            let YearAndWeekList = [];

            for(let i=1; i<=NextWeekcount; i++){
                let Day = Moment(StartDay).add(i*7, 'days');
                let YearAndWeek = {Year:parseInt(Day.format('GGGG')), Week:parseInt(Day.format('WW'))};
                YearAndWeekList.push(YearAndWeek);
            }

            return YearAndWeekList;
        },

        Set_SelectedYear(Year){
            this.G_SelectedYear= Year;
            return this.G_SelectedYear;
        },

        Set_SelectedWeek(Week){
            this.G_SelectedWeek=Week;
            return this.G_SelectedWeek;
        },

        //Bir sonraki haftayı alma
        Next_Week(SelectedYear,SelectedWeek){
            SelectedYear=parseInt(SelectedYear);
            SelectedWeek=parseInt(SelectedWeek);
            let WeekStartDate=this.Get_WeekStartDateBySelectedYearAndWeek(SelectedYear,SelectedWeek);
            let weekNumber=moment(WeekStartDate).add(7, 'days').week();
            let yearNumber=moment(WeekStartDate).add(7, 'days').year();

            yearNumber=(weekNumber<SelectedWeek)?yearNumber+1:yearNumber;

            return {Year:yearNumber, Week:weekNumber};
        },

        //Bir önceki haftayı alma
        Previous_Week(SelectedYear,SelectedWeek){
            SelectedYear=parseInt(SelectedYear);
            SelectedWeek=parseInt(SelectedWeek);
            let WeekStartDate=this.Get_WeekStartDateBySelectedYearAndWeek(SelectedYear,SelectedWeek);
            let weekNumber=moment(WeekStartDate).add(-7, 'days').week();
            let yearNumber=moment(WeekStartDate).add(-7, 'days').year();

            let endYear=moment(WeekStartDate).add(-7, 'days').endOf('isoWeek').year();
            let startYear=moment(WeekStartDate).add(-7, 'days').startOf('isoWeek').year();

            yearNumber=(endYear>startYear)?endYear:yearNumber;

            return {Year:yearNumber, Week:weekNumber};

        },

        //Hafta verilen yıl ve haftaya göre haftanın başlangıç gününü getirme
        Get_WeekStartDateBySelectedYearAndWeek(SelectedYear,SelectedWeek){
            let WeekStartDate=this.Calc_WeekStartDateByDate(moment().startOf('isoWeek').year(SelectedYear).week(SelectedWeek).format('YYYY-MM-DD'));
            return WeekStartDate;
        },

        //Hafta başlangıç tarihibi normal tarih formatına çevirme
        Calc_WeekStartDateByDate(d){
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6:1);
            return new Date(d.setDate(diff));
        },


        createDaysByStartDateEndDate(startDate, EndDate){
            let lst_days=[];
            let currentDate=startDate;
            lst_days.push(startDate);
            while (this.splitDate(currentDate).formatted_date<this.splitDate(EndDate).formatted_date){
                let date=this.splitDate(currentDate).NextDate;
                lst_days.push(date);
                currentDate=date;
            }
            return lst_days;
        },



        dateDiff(Date1, Date2, DiffFormat){
            Date1=moment(Date1,"YYYY-MM-DD HH:mm:ss");
            Date2=moment(Date2,"YYYY-MM-DD HH:mm:ss");
            let diffCount =  Date2.diff(Date1, DiffFormat);

            return diffCount;
        },

        dateAddDays(Date,Days){
            return moment(Date).add(Days, 'days');
        },


        ///Tarih formatlama ve parçalama
        splitDate(date) {

            date=moment(date,"YYYY-MM-DD HH:mm:ss");
            date=new Date(date.year(),date.month(),date.date(), date.hour(), date.minute(), date.second())


            return {
                dayOfTheWeek: this.gWeekDay(date),
                day: this.gDay(date),
                weekNumber:this.getWeekNumber(date),
                monthNumber: this.gMonth(date),
                year: this.gYear(date),
                formatted_date:moment(date).format("YYYY-MM-DD"),
                formatted_dateDDMM:moment(date).format("DD-MM"),
                formatted_dateHHMM:moment(date).format("HH:mm"),
                formatted_dateYYYYMMDDhhmmss:moment(date).format("YYYY-MM-DD HH:mm:ss"),
                formatted_dateYYYYMMDDhhmmss_String:moment(date).format("YYYYMMDDHHmmss"),
                formatted_dateYYYYMMDD:moment(date).format("YYYY-MM-DD"),
                formatted_dateMMMDDHHMM:moment(date).format("MMM-DD HH:mm"),
                PrevDate:moment(date).add(-1, 'days').format("YYYY-MM-DD"),
                NextDate:moment(date).add(1, 'days').format("YYYY-MM-DD"),
                full_date:date,
                weekStartDate:this.Get_WeekStartDateBySelectedYearAndWeek(this.gYear(date),this.getWeekNumber(date))
            }
        },

        //Saati dakikaya çevirme
        convertHourToMinute(Hour){
            var timeParts = Hour.split(":");
            return Number(timeParts[0]) * 60 + Number(timeParts[1]);
        },

        //SubEventlerin toplam sayısını otomatik hesaplama
        sumSubEventsReqCount(event){
            let count=0;
            try {
                for(let i=0; i<event.subEvents.length; i++){
                    event.subEvents[i].WES_RequiredCount=parseInt(event.subEvents[i].WES_RequiredCount);
                    count+=parseInt(event.subEvents[i].WES_RequiredCount);
                }

                event.WE_TotalRequiredCount=count;
            }catch (e){
                this.$notify({title: this.$t('general_Error'), text: e.message, type: "error"});
            }
            return count;
        },

        //Js tarihine göre hafta numarası alma
        getWeekNumber(d) {
            d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
            d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
            let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
            let weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
            return weekNo;
        },


        //Apiden dönen resul containerın severitsine göre bildirim gösterme
        API_ResultContainerControl(res) {
            let res_JSON=null;
            try {
                if (res.severity === 1) {
                    res_JSON=res.jsondata;
                } else if (res.severity === 2){
                    this.$notify({  title: 'Hata', text: res.lastError,  type: "error", duration: "15000" });
                }else if(res.severity === 3){
                    this.$notify({ title: 'Uyarı',  text: res.lastError,  type: "warning",  duration: "15000" });
                }
            }catch (ex){
                this.$notify({  title: 'Hata', text: ex,  type: "error", duration: "15000" });
            }

            return res_JSON;
        },

        API_ResultContainerControl_Swal(res){
            let res_JSON=null;
            try {
                if (res.severity === 1) {
                    res_JSON=res.jsondata;
                } else if (res.severity === 2){
                    this.$swal.fire({
                        icon: 'error',
                        title:  'Hata',
                        confirmButtonColor: '#C5381A',
                        confirmButtonText: 'Tamam',
                        text: res.lastError,
                    })
                    // this.$notify({  title: this.$t('general_Error'), text: res.lastError,  type: "error", duration: "5000" });
                }else if(res.severity === 3){
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'Uyarı',
                        confirmButtonColor: '#C5381A',
                        confirmButtonText: 'Tamam',
                        text: res.lastError,
                    })

                    // this.$notify({ title: this.$t('general_Warning'),  text: res.lastError,  type: "warning",  duration: "5000" });
                }
            }catch (ex){
                this.$notify({  title: this.$t('general_Error'), text: ex,  type: "error", duration: "15000" });
            }

            return res_JSON;
        },


        Control_SelectedWeekPrevWeek(SelectedYear, SelectedWeek){
            if(this.Calculated_SelectedYearWeek(SelectedYear,SelectedWeek) < this.Calculated_SelectedYearWeek(this.Now_Year, this.Now_Week)){
                return true;
            }
            return false;
        },

        //Sql için yıl ve hafta bilgisi oluşturma
        Calculated_SelectedYearWeek(SelectedYear, SelectedWeek)
        {
            return parseInt(SelectedYear) * 100 + parseInt(SelectedWeek);
        },

        //Resim adı için string oluşturma
        getStringNameOfImage(Id)
        {
            return (String('00000000' + Id).slice(-8));
        },


        //Dosya varmı yokmu
        async File_Exist(FilePath){
            let http = new XMLHttpRequest();
            http.open('HEAD', FilePath, true);
            http.send();
            return http.status!=404;
        },

        //Email validasyon
        ValidateEmail(inputText)
        {
            let regex = new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}');
            return  regex.test(inputText);
        },


        //Telefon validasyon
        ValidatePhoneNumber(inputText)
        {
            if(inputText.length<10 || inputText.length>15) return false;
            if(inputText.length>10)   inputText=inputText.slice(-10);

            var phoneno = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
            if(inputText.match(phoneno)) {
                return true;
            }
            else {
                return false;
            }
        },





    }
}


var modalMixin = {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit("close")
            if (typeof this.reset === 'function')
                this.reset();
        }
    }
};

var loadingMixin = {
    data() {
        return {
            isLoading: false
        }
    },
    methods: {
        showLoading() {
            this.isLoading = true
        },
        hideLoading(delay = 300) {
            _.delay(() => this.isLoading = false, delay)
        }
    }
};

var filterableMixin = {
    data() {
        return {
            filter: ""
        }
    },
    computed: {
        filteredData() {
            if (!this.filter || !this.filter.length)
                return this.data

            return this.data
                .filter((t) => {
                    var ok = false;
                    for (var key of Object.keys(t)) {
                        ok = _.lowerCase(t[key]).includes(_.lowerCase(this.filter));
                        if (ok) break;
                    }
                    return ok;
                });
        },
        filteredCountText() {
            if (this.data.length === this.filteredData.length)
                return `${this.data.length} entry(s) found.`;

            return `Showing ${this.filteredData.length} entry(s) (filtered from ${this.data.length})`
        }
    }
};

export {
    modalMixin, loadingMixin, filterableMixin, GlobalFunctionAndStateMixin
}
