<template>
    <div class="col-12">
        <div class="col-12 app-main p-0 px-2" style="">
            <keep-alive>
                <transition name="fade" mode="out-in">
                    <router-view class="view"></router-view>
                </transition>
            </keep-alive>
        </div>
    </div>


</template>


<script>
    export default {
        name: "LeftMenu",
      data() {
        return {
          searchText: "",
        }
      },
        computed: {


        },

    }
</script>

<style scoped>

    .pl-3 {
        padding-left: 25px !important;
    }

    .py-2 {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .ml-2 {
        margin-left: 10px !important;
    }

    .sidebar {
        position: relative;
        overflow: hidden;

    }

    .first-menu {
        overflow-y: scroll;
        position: fixed;
        background-color: #032148;
        height:90vh;
        width: 70px;
        transition: width 0.5s;
        border-radius: 0;
        padding-bottom: 10px;
        z-index: 1000;
        overflow-x: hidden;



    }

    .first-menu a {
        color: #fff;
    }

    .first-menu i {
        font-size: 24px;
    }

    .first-menu span {
        font-size: 14px;
        white-space: nowrap;
        font-family: 'Open Sans', sans-serif;
        opacity: 0;
        visibility: hidden;
    }

    .first-menu:hover span {
        opacity: 1;
        visibility: visible;
    }

    .first-menu .list-group-item {
        border-radius: 0;
    }

    .first-menu .list-group-item:hover {
        background-color: #032148;
        color: red;
    }

    .first-menu:hover {
        width: 270px;
    }

    .list-group-item {
        font-size: 14px;
    }

    .first-menu li:hover .submenu {
        visibility: visible;
        opacity: 1;
    }

    .list-group-item {
        background-color: transparent;
        border: none;
        border-radius: 0;
        color: white;
    }



    ::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #292961
    }

</style>
