import http from "../http";

import TokenService from "./token.service";

const AuthService = {
    login: async (email, password,token) => {
        const response = await http.instance.post("/auth/login", {email: email, password: password,token:token});
        TokenService.setToken(response.data.access_token, response.data.refresh_token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("permissions" ,JSON.stringify(response.data.user.permissions))
        localStorage.setItem("roles" ,JSON.stringify(response.data.user.roles))

        http.setHeader();
        http.mountAuthInterceptor();

        return response.data.user;
    },
    refreshToken: async () => {
        const response = await http.instance.post("/auth/refresh", {refresh_token: TokenService.getRefreshToken()});

        TokenService.setToken(response.data.access_token, response.data.refresh_token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("permissions" ,JSON.stringify(response.data.user.permissions))
        localStorage.setItem("roles" ,JSON.stringify(response.data.user.roles))
        http.setHeader();

        return response.data.user;
    },
    logout: () => {
        TokenService.removeToken();
        localStorage.removeItem("user");
        localStorage.removeItem("auth_token")
        localStorage.removeItem("userEmail")

        http.clearHeader();
        http.unmountAuthInterceptor();
    }
};

export default AuthService
