<template>
  <nav class="navbar navbar-expand-lg bg-light shadow-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/dashboardpage">
        <img src="https://www.neseteknoloji.com/images/logo.png" style="height: 32px; width: auto;"> Neşe Teknoloji - Stock Grid
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
<!--        <ul class="navbar-nav me-auto mb-2 mb-lg-0 mt-2">
          <li class="nav-item ml-4">
            <router-link class="nav-link" :to="{name: 'Dashboard'}">
              Dashboard
            </router-link>
          </li>
        </ul>-->
      </div>
    </div>
  </nav>


</template>

<script>
    import {mapActions, mapState} from "vuex";
    export default {
        name: "AppHeader",
        data() {
            return {}
        },
        computed: {
            ...mapState("auth", ["user"]),

        },
        methods: {
            ...mapActions("auth", ["logout"]),

        }
    }
</script>

<style scoped>
    .navbar-collapse a , .text-style {
      font-family: Helvetica, Arial;
      font-size: 16px;
      color: #383838;
    }
    .nav-link {
      border-bottom: 2px solid transparent;
      transition: unset;
    }
    .nav-link:hover {
      border-bottom: 2px solid red;

    }
    .navbar-nav > li{
      margin-right:30px;
    }
    .router-link-exact-active{
      border-bottom: 2px solid red;
    }
    @media (max-width: 992px) {
      .router-link-exact-active{
        border-bottom: unset;
      }
      .nav-link:hover {
        border-bottom: unset;
      }
      .web-img{
        display: none;
      }
      .mobile-margin{
        margin: unset !important;
      }
    }
    .bg-light{
      background-color: #ffffff!important;
    }
    .dropdown-item:active{
      background-color: #eaedf2;;
    }



</style>
