import axios from 'axios'
import TokenService from "@/services/token.service";
import store from "../store";
import router from "../router";

const http = {
    instance: null,
    authInterceptor: null,
    authTrials: 0,
    init: function () {
        this.instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            json: true
        })
    },

    mountAuthInterceptor: function () {
        this.authInterceptor = this.instance.interceptors.response.use((response) => {
            this.authTrials = 0;
            // eslint-disable-next-line no-undef
            NProgress.done()
            return response
        }, async (error) => {
            if (!error.response || error.response.status === 412) {
                // eslint-disable-next-line no-undef
                NProgress.done();
                return null;
            }
            if (error.response.status === 401) {
                if (error.config.url.includes("/auth/refresh") || this.authTrials >= 3) {
                    store.dispatch("auth/logout");
                    throw error
                } else {
                    try {
                        this.authTrials++;
                        await store.dispatch("auth/refreshToken");
                        return this.instance.request({
                            data: error.config.data,
                            method: error.config.method,
                            url: error.config.url,
                            contentType:'application/json',
                            headers: {"content-type": "application/json;charset=utf-8"},
                            params: error.config.params
                        })
                    } catch (e) {
                        throw error
                    }
                }
            } else if (error.response.status === 403) {
                router.push({name: 'Forbidden'});
            } else if (error.response.status === 200) {
                // eslint-disable-next-line no-undef
                NProgress.done();
            }

            throw error;
        });
    },
    unmountAuthInterceptor: function () {
        this.instance.interceptors.response.eject(this.authInterceptor)
    },
    setHeader: function () {
        this.instance.defaults.headers.common.Authorization = `Bearer ${TokenService.getAccessToken()}`
    },
    clearHeader: function () {
        this.instance.defaults.headers.common = {}
    }
};

function createUrl(rel) {
    console.log(rel)
    return process.env.VUE_APP_API_URL + rel;
}


export {http as default, createUrl};
